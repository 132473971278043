<template>
    <b-container fluid>
        <div v-if="settings.KVKAPI && !user.kvkcode && user.role=='user'" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.search") }} {{ $t("labels.kvknumber") }}</label>

            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="searchkvkcode" />
            </div>
            <div  class="col-3">
                <div v-if="searchkvkcode!=null" class="btn btn-primary" @click="searchKVK(); "><font-awesome-icon icon='search'   />{{ $t("labels.search") }} {{ $t("labels.kvknumber") }}
                </div>
            </div>
        </div>
        <form class="form">
            <input type="hidden" v-model="count"/>
            <!-- Username -->
            <div class="form-row" v-if="false">
                <div class="col-3">
                    <font-awesome-icon v-if="usernamecheck" icon="check" style="color: green;" />
                    <font-awesome-icon v-else icon="times" style="color: red;" />
                    <label for=""> {{ $t("labels.username") }}</label>
                </div>
                <div class="col">
                    <b-form-input :disabled="user_id != 0" type="text" class="form-control" :state="usernamecheck"
                        v-model="user.username" />
                    <b-form-invalid-feedback>
                        {{ $t("validation.username") }}
                    </b-form-invalid-feedback>
                </div>
            </div>

            <!-- Password -->
            <div v-if="false" class="form-row">
                <div class="col-3">
                    <font-awesome-icon v-if="passwordcheck" icon="check" style="color: green;" />
                    <font-awesome-icon v-else icon="times" style="color: red;" />
                    <label for=""> {{ $t("labels.password") }}</label>
                </div>
                <div class="col">
                    <b-input-group class="mt-3">
                        <b-form-input :type="pwtype" v-model="user.password" :state="passwordcheck" placeholder="" />
                        <b-input-group-append>
                            <b-button class="multi-append"  href="javascript:;" @click="reveal()">
                                <font-awesome-icon :icon=btnText />
                            </b-button>
                        </b-input-group-append>
                        <b-form-invalid-feedback>
                            {{ $t("validation.password") }}
                        </b-form-invalid-feedback>
                    </b-input-group>
                </div>
            </div>
            <!--- branche -->
            <div class="form-row">
                <div class="col-3">
                    <font-awesome-icon v-if="user.branche_id != 0" icon="check" style="color: green;" />
                    <font-awesome-icon v-else icon="times" style="color: red;" />
                    <label for=""> {{ $t("labels.branche") }}</label>
                </div>
                <div class="col">
                    <b-form-select :state="user.branche_id != 0" name="branche" class="form-control branche"
                        v-model="user.branche_id">
                        <option v-for="(branche, idx) in activebranches" :key="idx" v-bind:value="branche.id">
                            {{ branche["name"] }}
                        </option>
                    </b-form-select>
                    <b-form-invalid-feedback>
                        {{ $t("validation.branche") }}
                    </b-form-invalid-feedback>
                </div>
            </div>
            <div v-if="createAny('users') && settings.EnableEmployees" class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("meta.isemployee") }}</label>
                </div>
                <div class="col">
                    <b-form-checkbox  @change="setEmployee" v-model="user.isemployee" value="1" unchecked-value="0">{{ $t("meta.isemployee") }}</b-form-checkbox>
                </div>
            </div>            
            <!-- Role -->
            <div v-if="createAny('users') && employeecheck" class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.role") }}</label>
                </div>
                <div class="col">
                    <b-form-select v-model="user.role" class="form-control">
                        <option value="user"> {{ $t("option.zzpr") }}</option>
                        <option value="contactpersoon">{{ $t("option.contactperson") }}</option>
                        <!-- <option value="opdrachtgever">{{ $t("option.client") }}</option> -->
                        <option value="admin">{{ $t("option.admin") }}</option>
                    </b-form-select>
                    <b-form-invalid-feedback>
                        {{ $t("validation.role") }}
                    </b-form-invalid-feedback>
                </div>
            </div>


            <!-- owner -->
            <div v-if="createAny('users')" class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.addedby") }}</label>
                </div>
                <div class="col">
                    <select name="admin" class="form-control admins" v-model="user.owner_id">
                        <option v-for="(user, idx) in owners" :key="idx" v-bind:value="user.id">
                            {{ user["firstname"] }} {{ user["middlename"] }} {{ user["lastname"] }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- dynamic ordermetas -->
            <div v-for="meta in user_metas" v-bind:key="meta.key">
                <div class="form-row" v-if="skipmeta(meta)">
                    <div class="col-3">
                        <font-awesome-icon v-if="meta.label == 'Email' && emailcheck" icon="check"
                            style="color: green;" />
                        <font-awesome-icon v-else-if="meta.label == 'Email'" icon="times" style="color: red;" />
                        <label v-bind:for="meta.key">{{ translate(meta.label) }}</label>
                    </div>
                    <div v-if="meta.label == 'Aanhef'">
                        <b-form-group>
                            <b-form-radio v-model="user[meta.key]" value="man">{{ $t("meta.aanhefman") }}</b-form-radio>
                            <b-form-radio v-model="user[meta.key]" value="vrouw">{{ $t("meta.aanhefvrouw") }}
                            </b-form-radio>
                        </b-form-group>
                    </div>
                    <div v-else-if="meta.label == 'Email'" class="col"
                        v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                        <b-input-group>
                            <b-form-input :state="emailcheck" v-bind:type="meta.type" @input="emailFieldChanged"
                                v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control"
                                v-model="user[meta.key]" />
                            <b-form-invalid-feedback>
                                {{ $t("validation.email") }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.label == 'Zipcode'" class="col"
                        v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                        <b-input-group>
                            <b-form-input :state="lookup" v-bind:type="meta.type"
                                v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control"
                                v-model="user[meta.key]"  @blur="findadres()"/>
                            <b-form-invalid-feedback>
                                {{ postcodecheck }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.label == 'Housenumber'" class="col"
                        v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                        <b-input-group>
                            <b-form-input :state="lookup" v-bind:type="meta.type"
                                v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control"
                                v-model="user[meta.key]" @blur="findadres()"/>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.label == 'Country'" class="col"
                        v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                        <b-input-group>
                            <select v-bind:type="meta.type" v-bind:id="meta.key" class="form-control"
                                v-model="user[meta.key]">
                                <option value='NL'>{{ $t("countries.NL") }}</option>
                                <option value='DE'>{{ $t("countries.DE") }}</option>
                                <option value='BE'>{{ $t("countries.BE") }}</option>
                                <option value='LT'>{{ $t("countries.LT") }}</option>
                                <option value='RO'>{{ $t("countries.RO") }}</option>
                                <option value='PL'>{{ $t("countries.PL") }}</option>
                                <option value='TR'>{{ $t("countries.TR") }}</option>
                            </select>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.type != 'select'" class="col"
                        v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                        <b-input-group>
                            <b-form-input v-bind:type="meta.type" v-bind:placeholder=translate(meta.label)
                                v-bind:id="meta.key" class="form-control" v-model="user[meta.key]" />
                        </b-input-group>
                    </div>
                    <!-- <div class="col" v-else>
                        <select v-if="createAny('users')" name="userrating" class="form-control"
                            v-model="user[meta.key]">
                            <option val='1'>*</option>
                            <option val='2'>**</option>
                            <option val='3'>***</option>
                            <option val='4'>****</option>
                            <option val='5'>*****</option>
                        </select>
                        <div v-else>{{ user[meta.key] }}</div>
                    </div> -->
                </div>
            </div>

        </form>
    </b-container>
</template>

<script>
// import axios from 'axios'
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_confirm from '../PopupConfirm.vue'

export default {
    name: 'userdefaultsscreen',
    data() {
        return {
            pwtype: 'password',
            btnText: 'eye',
            //eslint-disable-next-line
            reg: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            //            reg: /^\w+([\.-]?)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,

            postcodecheck: 'invalid',
            findadresresult: false,
            searchpc:"",
            searchnumber:-1,
            useremail:"",
            count:0,
            searchkvkcode:null
        }
    },
    mounted(){
            // console.log("mounted", this.activebranches)
            if(this.activebranches.filter(branche => branche.id == this.user.branche_id).length==0){
                this.user.branche_id = this.activebranches[0].id;
            }
    },
    props: {
        user_id: Number
    },
    computed: {
    employeecheck(){
        return this.settings.EnableEmployees==false || (this.settings.EnableEmployees==true && (this.user.isemployee==0||this.user.isemployee==undefined))
    },
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new',
            'createAny',
            'owners'

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
            'branches',
        ]),

        usernamecheck() {
            if (this.user.id != 0) {
                return true
            }
            let found = false;
            for (var i = 0; i < this.users.length; i++) {
                if (this.user.username == this.users[i].username) {
                    found = true;
                    break;
                }
            }
            return !found && this.user.username != undefined && this.user.username.length > 5;
        },
        passwordcheck() {
            if (this.user.id == 0) {
                return this.user.password != undefined && this.user.password.length > 5;
            } else {
                return true
            }

        },
        emailcheck() {
            //eslint-disable-next-line
            return this.user.email != undefined && this.reg.test(this.user.email) && this.users.filter( u =>(u.email == this.user.email) && (u.id != this.user.id)).length==0
        },
        lookup() {
            this.findadres();
            return true// this.findadresresult
        }
    },
    methods: {
        skipmeta(meta){
            let toskip = ['invoice','speaks', 'debnum' , 'signupdate', 'waardering', 'btw_verlegd', 'extra_branches', 'avb','avb_enddate','website']
            return toskip.indexOf(meta.label)==-1
        },
        async searchKVK(){
            console.log("searcjsonhKVsssK",this.user.kvkcode)
            const response = await axios.get('/api/kvk/'+this.searchkvkcode)
            console.log(response)
            if(response.data.status==400){
                  this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: this.$t('labels.kvkzoekfout'),
                      header: this.$t('labels.kvkcode')
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '400px'
                  })
            } else {
                this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: this.$t('labels.kvkzoekgoed'),
                      header: this.$t('labels.kvkcode')
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '400px'
                  })
                this.user.companyname = response.data.naam?response.data.naam:""
                this.user.kvk = "yes"
                this.user.kvkcode = this.searchkvkcode
                if(response.data._embedded && response.data._embedded.hoofdvestiging){
                    if(response.data._embedded.hoofdvestiging.adressen.length>0){
                        let adresdata = response.data._embedded.hoofdvestiging.adressen[0]
                        this.user.postcode = adresdata.postcode
                        this.user.housenumber = adresdata.huisnummer
                        this.user.adres = adresdata.straatnaam
                        this.user.city = adresdata.plaats
                        this.user.country= adresdata.latlongaddress
                    }
                }
            }
        },        
        findadres() {
            if(this.user.postcode){
                var numbers = this.user.postcode.substring(0,4)
                var letters = this.user.postcode.substring(4).replaceAll(' ', '').toUpperCase().substring(0,2)
                if(letters.length==2 && numbers.length==4){
                    this.user.postcode = numbers+ " "+ letters
                    this.count++
                }
            }

            if(this.user.postcode && parseInt(this.user.housenumber) &&(this.searchnumber!=parseInt(this.user.housenumber) || this.searchpc != this.user.postcode)) {
                this.searchnumber=parseInt(this.user.housenumber) 
                this.searchpc = this.user.postcode
                fetch(
                    "https://api.pro6pp.nl/v2/autocomplete/nl?authKey=1u9iXssOsxr8wRR5&postalCode=" + this.user.postcode + "&streetNumberAndPremise="+this.user.housenumber,
                    {
                        method: "GET",
                    }
                ).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    //   throw new Error('Something went wrong');
                })
                .then((data) => {
                    this.user.adres = data.street
                    this.user.city = data.settlement
                    this.user.province = data.province
                    this.user.lat = data.lat
                    this.user.lng = data.lng
                    this.user.latlongaddress = this.user.adres + " " + this.user.housenumber + " " + this.user.city + " " + this.user.country
                    this.findadresresult = true  
                    this.count++
                })
                .catch((error) => {
                    console.log(error)
                });

            }

        },
        reveal() {
            if (this.pwtype === 'password') {
                this.pwtype = 'text'
                this.btnText = 'eye-slash'
            } else {
                this.pwtype = 'password'
                this.btnText = 'eye'
            }
        },

        translate(path) {
            return path?this.$i18n.t('meta.' + path.toLowerCase()):""
        },
        ...mapActions([
            'save_user',
            'add_user'
        ]),
        validated() {
            return this.passwordcheck &&  this.user.branche_id != 0 && this.emailcheck
        },
        emailFieldChanged(){
            this.user.username = this.user.email
            this.$emit('change')
        },
        setEmployee(){
            console.log("Set employee", this.user.isemployee)
            if(this.user.isemployee==1){
                this.user.role="user"
            }
        }


    },
    watch:{
        postcode(c){
            console.log("pc", c)
        }
    }
}
</script>