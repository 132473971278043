<template>
	<header ref="Logo" id="titlebar" :style="statusColor">
		<FrontendLogo v-if="buildtarget!='apprelease'"/>
		<div v-if="current_user && current_user.role=='admin' && buildtarget!='apprelease'">{{ buildVersion }}</div>
		<b-spinner v-if="loading" small></b-spinner>
		<Navigation :class="{ 'nav-visible': navVisible }" v-if="!settings.carouselPortal" />
		<CarouselNavigation v-else />
		<LocaleSwitcher v-if="buildtarget!='apprelease'"/>
		<div class="hamburger"  :style="AppSecondaryColor()" id="toggle-nav" @click="navVisible = !navVisible">Menu
			<font-awesome-icon  :style="AppPrimaryColor()" :icon="navVisible ? 'times' : 'bars'" />
		</div>

	</header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navigation from './Navigation'
import CarouselNavigation from './CarouselNavigation.vue'
import LocaleSwitcher from "@/components/LocaleSwitcher"
import FrontendLogo from './parts/FrontendLogo.vue'

export default {
	name: 'title-bar',
	data() {
		return {
			navVisible: false,
			statusColor: 'background-color:#ffffff',
			buildVersion:"26032025"

		}
	},
	mounted() {
		this.$router.afterEach(()  => {
			this.navVisible = false
			if(this.$refs["Logo"]){
				this.$refs["Logo"].scrollIntoView({ behavior: "smooth" })
			}

		})
		if(window.location.href.indexOf('ment.fly')==15) {
			this.statusColor = 'background: #aaaaaa; padding:3px;'
		}
	},
	computed: {
		...mapGetters([
			'is_logged_in',
			'server_url',
			'portal_logo'

		]),
		...mapState([
			'server_online',
			'loading'
		]),
		location(){
			return window.location
		},
	
	},
	methods: {
		logoClick(){
			console.log("logoClick")
			this.$store.commit('appnavvisible', true)
		}
	},
	components: { Navigation, LocaleSwitcher,FrontendLogo ,CarouselNavigation},
}
</script>
